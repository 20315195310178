import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../utils/Constants';
import { useAccessToken } from '../../hooks/useAccessToken';
import { useKeywordsData } from '../../hooks/useKeywordsData';
import useRefreshQueries from '../../hooks/useRefreshQueries';
import * as CONSTANTS from '../../utils/TableColumnsConstants';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid2'
import Search from '../../components/Search';
import TendencyChart from '../../components/TendencyChart';
import PaperItem from '../../components/Essential/PaperItem';
import TableComponent from '../../components/TableComponent';
import ViewMoreButton from '../../components/ViewMoreButton';
import KeywordMetadata from '../../components/KeywordMetadata';
import LevelCompetition from '../../components/LevelCompetition';
import CustomTooltip from '../../components/CustomTooltip/CustomTooltip';
import KeywordMetrics from '../../components/KeywordMetrics/KeywordMetrics';
import { useSearchCache } from '../../context/SearchCacheContext';
import { useKeywordsIdeasCache } from '../../context/KeywordsIdeasCacheContext';
import { formatNumber } from '../../utils/Utils'
import '../../styles/analysis.scss';

const KEYWORDS_ANALYSIS_PAGE_CACHE_KEY = 'keywordsAnalysisPage';
const KEYWORDS_IDEAS_PAGE_CACHE_CONTEXT = 'keywordsIdeasSearch';

export const KeywordsAnalysis: React.FC = () => {
  const { t } = useTranslation();
  const token = useAccessToken();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { query, location, handleQuery, handleLocation } = useRefreshQueries(KEYWORDS_ANALYSIS_PAGE_CACHE_KEY);
  const {
    keywords, keywordSearched, keywordOverviewItems, loadingKeywords,
    loadingKeywordOverviewItems, fetchKeywords, fetchKeywordOverview, fetchKeywordsWithQueries,
  } = useKeywordsData(KEYWORDS_ANALYSIS_PAGE_CACHE_KEY);

  const { getSearchCache, updateSearchCache, getLocation, updateLocation } = useSearchCache();
  const { updateKeywordsIdeasCache, updateLocation: updateKeywordsIdeasLocation } = useKeywordsIdeasCache();

  const [hasInitialized, setHasInitialized] = useState(false);

  const handleSearchBtn = useCallback(async (search: string) => {
    if (!search) return;
    handleQuery(search);
    updateSearchCache(KEYWORDS_ANALYSIS_PAGE_CACHE_KEY, search); 

    const data = { keywords: search, location: getLocation(KEYWORDS_ANALYSIS_PAGE_CACHE_KEY), mode: 'kws' };
    await Promise.all([fetchKeywordOverview(data), fetchKeywords(data)]);
  }, [fetchKeywords, fetchKeywordOverview, handleQuery, updateSearchCache, getLocation]);

  const handleLocationUpdate = (newLocation: number) => {
    handleLocation(newLocation);
    updateLocation(KEYWORDS_ANALYSIS_PAGE_CACHE_KEY, newLocation);
  }

  const redirectToIdeas = useCallback(() => {
    const cachedQuery = getSearchCache(KEYWORDS_ANALYSIS_PAGE_CACHE_KEY);
    if (cachedQuery && location) {
      updateKeywordsIdeasCache(KEYWORDS_IDEAS_PAGE_CACHE_CONTEXT, cachedQuery);
      updateKeywordsIdeasLocation(KEYWORDS_IDEAS_PAGE_CACHE_CONTEXT, location);

      setTimeout(() => {
        navigate(`${ROUTES.IDEAS}?q=${cachedQuery}&db=${location}`);
      }, 100);
    }
  }, [getSearchCache, location, updateKeywordsIdeasCache, updateKeywordsIdeasLocation, navigate]);

  const dataLevelCompetition = useMemo(() => ({
    cpc: keywordSearched?.keyword_info?.cpc,
    intent_info: keywordSearched?.search_intent_info,
    competition: keywordSearched?.keyword_info?.competition,
    time_update: keywordSearched?.keyword_info?.time_update,
    low_top: keywordSearched?.keyword_info?.low_top_of_page_bid,
    high_top: keywordSearched?.keyword_info?.high_top_of_page_bid,
  }), [keywordSearched]);

  const resultsLevelCompetition = useMemo(() => ({
    item_types: keywordSearched?.serp_info?.item_types || [],
    se_results_count: keywordSearched?.serp_info?.se_results_count,
  }), [keywordSearched]);

  useEffect(() => {
    fetchKeywordsWithQueries();
  }, [token, searchParams, fetchKeywordsWithQueries]);

  useEffect(() => {
    if (!hasInitialized) {
      const cachedQuery = getSearchCache(KEYWORDS_ANALYSIS_PAGE_CACHE_KEY);
      const cachedLocation = getLocation(KEYWORDS_ANALYSIS_PAGE_CACHE_KEY);

      if (cachedQuery !== query || cachedLocation !== location) {
        if (cachedQuery !== query) {
          handleQuery(cachedQuery);
        }
        if (cachedLocation !== location) {
          handleLocation(cachedLocation);
        }
      }
      setHasInitialized(true);
    }
  }, [hasInitialized, getSearchCache, getLocation, query, location, handleQuery, handleLocation]);

  return (
    <Grid container spacing={2} className="container-analysis">
      <Grid size={{ xs:12, sm:12, md:12, lg:12, xl:12 }} >
        <PaperItem className="boxShadow" sx={{ p: 3 }}>
          <Search
            cacheKey={KEYWORDS_ANALYSIS_PAGE_CACHE_KEY}
            placeholder={t('search_placeholder_keyword')}
            loading={loadingKeywords}
            onSearch={handleSearchBtn}
            onCountry={handleLocationUpdate}
          />
        </PaperItem>
      </Grid>
      
      <Grid className="container-cart-date" size={{ xs:12, sm:4, md:3}} >
        <PaperItem className="boxShadow" sx={{ p: 3 }}>
          <KeywordMetrics data={keywordSearched} />
        </PaperItem>
      </Grid>

      <Grid className="container-cart-date" size={{ xs:12, sm:8, md:6}} >
        <PaperItem className="boxShadow" sx={{ p: 3 }}>
          <CustomTooltip content={t('tooltips.keywords.explore_tendencies')}>
            <Typography>{t('tendencies')}</Typography>
          </CustomTooltip>
          <TendencyChart data={keywordSearched?.keyword_info?.history} />
        </PaperItem>
      </Grid>

      <Grid size={{ xs:12, sm:12, md:3}} >
        <PaperItem className="boxShadow" sx={{ px: 3, height: '100%' }}>
          <LevelCompetition data={dataLevelCompetition} results={resultsLevelCompetition} />
        </PaperItem>
      </Grid>

      <Grid size={{ xs:12, sm:12, md:12, lg:12, xl:12 }} >
        <PaperItem className="boxShadow" sx={{ p: 3 }}>
          <div className="flex flex-col gap-2">
            <CustomTooltip content={t('tooltips.keywords.keyword_ideas_title')}>
              <Typography>{t('keyword_ideas')}</Typography>
            </CustomTooltip>
            <KeywordMetadata metadata={keywords} />
          </div>
          <TableComponent
            rowBuffer={0}
            columnBuffer={0}
            rows={keywords.items}
            loading={loadingKeywords}
            columns={CONSTANTS.COLUMNS_KEYWORD_ANALYSIS(t, {
              code: location
            })}
            paginationMode="client"
            sortingMode="client"
            hideFooter
          />
          {keywords.items.length > 0 && (
            <ViewMoreButton onClick={redirectToIdeas}>
              {`${t('view')} ${formatNumber(keywords?.totalKeywords)} ${t('more_keywords').toLocaleLowerCase()}`}
            </ViewMoreButton>
          )}
        </PaperItem>
      </Grid>

      <Grid size={{ xs:12, sm:12, md:12, lg:12, xl:12 }} >
        <PaperItem className="boxShadow" sx={{ p: 3 }}>
          <CustomTooltip content={t('tooltips.keywords.explore_serp_overview')}>
            <Typography className="mb-5">{t('serp_overview')}</Typography>
          </CustomTooltip>
          <TableComponent
            getRowHeight={() => 'auto'}
            loading={loadingKeywordOverviewItems}
            rows={keywordOverviewItems.items.serp_info?.serp}
            columns={CONSTANTS.COLUMNS_KEYWORD_SERP_OVERVIEW(t, { code: location })}
            paginationMode="client"
            sortingMode="client"
          />
        </PaperItem>
      </Grid>
    </Grid>
  );
};