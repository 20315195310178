import './Search.scss';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { CircularProgress, IconButton, InputBase } from '@mui/material';
import CountryWithCodeSelector from '../CountryWithCodeSelector/CountryWithCodeSelector';
import { useSearchCache } from '../../context/SearchCacheContext';

interface Props {
  loading?: boolean
  placeholder?: string
  defaultValue?: string
  onSearch?: (values: string) => void
  onChange?: (values: string) => void
  onCountry?: (country: number) => void
  cacheKey?: string
  allowEmptySearches?: boolean
  lines?: number
  maxLines?: number
}

const Search: FC<Props> = ({
                                   loading,
                                   onChange,
                                   onSearch,
                                   onCountry,
                                   placeholder,
                                   defaultValue,
                                   cacheKey = 'default',
                                   lines,
                                   maxLines
                                 }) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { getSearchCache, getLocation, updateSearchCache, updateLocation, getAllCacheContext } = useSearchCache();

  const [inputValue, setInputValue] = useState<string>(getSearchCache(cacheKey) || defaultValue || '');
  const [initialLocation, setInitialLocation] = useState<number>(getLocation(cacheKey));

  useEffect(() => {
    const keywords = searchParams.get('q');
    if (keywords && keywords !== inputValue) {
      setInputValue(keywords);
    }
  }, [searchParams]);

  useEffect(() => {
    const db = searchParams.get('db');
    if (db) {
      const dbNumber = parseInt(db, 10);
      setInitialLocation(dbNumber);
      updateLocation(cacheKey, dbNumber);
    }
  }, [searchParams, updateLocation, cacheKey]);

  const logCacheData = useCallback(() => {
  }, [getAllCacheContext]);

  const onBtnSearch = useCallback(() => {
    if (onSearch) {
      onSearch(inputValue);
      updateSearchCache(cacheKey, inputValue);
      logCacheData();
    }
  }, [inputValue, onSearch, updateSearchCache, cacheKey, logCacheData]);

  const onBtnClearClick = useCallback(() => {
    if (onSearch) {
      //onSearch('');
      updateSearchCache(cacheKey, '');
      setInputValue('');
      logCacheData();
    }
  }, [onSearch, updateSearchCache, cacheKey, logCacheData]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = e.target.value;
    if (onChange) {
      onChange(value);
    }
    setInputValue(value);
  };

  const onBtnEnterSearch = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (event.key === 'Enter') {
      onSearch?.(event.currentTarget.value);
      updateSearchCache(cacheKey, event.currentTarget.value);
      logCacheData();
    }
  };

  const handleCountryChange = (location: number) => {
    // Clear search params to avoid issues when arrive from redirection
    setSearchParams({}, { replace: true });

    setInitialLocation(location);
    updateLocation(cacheKey, location);
    if (onCountry) {
      onCountry(location);
    }
  };

  const handleKeyDown = (event) => {

    if(event.key !== 'Enter'){
      return
    }

    if(lines===undefined && inputValue.trim() !== ''){
      onBtnEnterSearch(event)
    }

    if(maxLines!==undefined){
      const numberOfRows = event.target.value.split('\n').length;
      if (numberOfRows >= maxLines) {
        event.preventDefault();
      }
    }


  }

  return (
    <div className="search">
      {onCountry && (
        <>
          <div className={'countrySelector'}><CountryWithCodeSelector initialLocation={initialLocation}
                                                                      onChange={(e) => handleCountryChange(e.location)} />
          </div>
        </>
      )}
      <div className={'input'}>
        <InputBase
          disabled={loading}
          sx={{ ml: 1, flex: 1 }}
          onKeyDown={(event) => handleKeyDown(event)}
          value={inputValue}
          placeholder={placeholder ?? t('button.search')}
          onChange={handleInputChange}
          multiline={lines>1}
          rows={lines>1?lines:1}
        />
        <IconButton
          type="button"
          aria-label="search"
          title={t('button.clear')}
          onClick={onBtnClearClick}
          sx={{ p: '5px', borderRadius: 0 }}
          style={{ opacity: inputValue ? 1 : 0 }}>
          <ClearIcon />
        </IconButton>
      </div>
      <IconButton
        type="button"
        disabled={loading}
        aria-label="search"
        className="btnSearch"
        onClick={onBtnSearch}
        data-testid="search-button"
        title={t('button.search')}>
        {loading ? (
          <CircularProgress
            className={'progress'}
            size={20}
            data-testid="loading-indicator"
          />
        ) : (
          <SearchIcon sx={{ height: '25px' }} />
        )}
      </IconButton>
    </div>
  );
};

export default Search;