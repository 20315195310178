import { createContext, useState, useEffect, useContext, ReactNode, useCallback } from 'react';

interface KeywordsCacheContextType {
  getCache: (key: string) => any;
  updateCache: (key: string, newData: any) => void;
}

const KeywordsCacheContext = createContext<KeywordsCacheContextType | undefined>(undefined);

interface KeywordsCacheProviderProps {
  children: ReactNode;
}

const LOCAL_STORAGE_KEY = 'multiKeywordsCache';

export const KeywordsCacheProvider: React.FC<KeywordsCacheProviderProps> = ({ children }) => {
  const [caches, setCaches] = useState(() => {
    const storedCaches = localStorage.getItem(LOCAL_STORAGE_KEY);
    return storedCaches ? JSON.parse(storedCaches) : {};
  });

  const getCache = useCallback((key: string) => {
    const cache = caches[key] || {};
    return cache;
  }, [caches]);

  const updateCache = useCallback((key: string, newData: any) => {
    setCaches(prevCaches => {
      const updatedCache = { ...prevCaches, [key]: { ...prevCaches[key], ...newData } };
      return updatedCache;
    });
  }, []);

  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(caches));
  }, [caches]);

  return (
    <KeywordsCacheContext.Provider value={{ getCache, updateCache }}>
      {children}
    </KeywordsCacheContext.Provider>
  );
};

export const useKeywordsCache = () => {
  const context = useContext(KeywordsCacheContext);
  if (!context) {
    throw new Error('useKeywordsCache must be used within a KeywordsCacheProvider');
  }
  return context;
};