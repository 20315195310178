import { useTranslation } from 'react-i18next';
import './TendencyChart.css';

import { HistoryTrendResponse } from '../../types';
import { formatNumber, formatStringDate, generateUuid, getNamePropertiesFromObjectArray } from '../../utils/Utils';

import { Skeleton } from '@mui/material';
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

interface Props {
  data?: HistoryTrendResponse[];
}

const MIN_LENGTH = 12;

const getChangePercent = (data: HistoryTrendResponse[], item: any, dataPropertiesNames: any) => {
  const index = data.findIndex((x) => x.date === item.payload.date);
  if (!index || index == -1 || index < 1 || index === data.length) return '';

  const valueBeforeColumn = data[index - 1][dataPropertiesNames[2]];
  const value = item.payload[dataPropertiesNames[2]];
  const percent = (value * 100) / valueBeforeColumn - 100;
  const sign = percent > 0 ? '+' : '';
  const clsName = percent === 0 ? 'default' : percent > 0 ? 'positive' : 'negative';
  return (
    <p className={`percent ${clsName}`}>
      {sign}
      {percent.toFixed(0)}%
    </p>
  );
};

const TendencyChart: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation();
  if (data === undefined) {
    return (
      <div className="container-chart">
        <div className="skeleton" style={{ minHeight: '100%' }}>
          {Array.from({ length: MIN_LENGTH }).map(() => (
            <Skeleton
              key={generateUuid()}
              height={240}
              className="flex"
              variant="rectangular"
              width={`${100 / MIN_LENGTH}%`}
              style={{ alignSelf: 'flex-end', overflow: 'hidden' }}
            />
          ))}
        </div>
      </div>
    );
  } else if (data.length === 0) {
    return (
      <div className="flex items-center justify-center container-chart">
        <p>{t('no_rows')}</p>
      </div>
    );
  } else {
    const dataPropertiesNames = getNamePropertiesFromObjectArray({ data });
    return (
      <div className="flex container-chart">
        <ResponsiveContainer
          minHeight="100%"
          width="100%"
          height={270}
          className="flex container-chart-responsive">
          <BarChart data={data as any[]} className="bar-chart">
            <Bar dataKey={dataPropertiesNames[2]} fill="rgb(126 58 242)" radius={[10, 10, 0, 0]} name={t('headerName.keyword_info.search_volume')} />
            <XAxis dataKey={dataPropertiesNames[1]} tick={false} axisLine={false}  />
            <YAxis tick={false} axisLine={false} width={0} />
            <Tooltip
              cursor={false}
              wrapperStyle={{ outline: 'none' }}
              labelFormatter={() => ''}
              formatter={function(value:number, a, item) {
                const percent = getChangePercent(
                  data as HistoryTrendResponse[],
                  item,
                  dataPropertiesNames
                );
                return (
                  <span>
                    {formatNumber(value)}
                    {percent}
                  </span>
                );
              }}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  }
};

export default TendencyChart;
